import React from 'react';

const LacticAcid = (props: React.PropsWithChildren<React.SVGProps<SVGSVGElement>>) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="39.906"
    height="48.595"
    viewBox="0 0 39.906 48.595"
  >
    <defs>
      <clipPath>
        <rect width="39.906" height="48.595" fill="currentColor" />
      </clipPath>
    </defs>
    <g clipPath="url(#clip-path)">
      <path
        d="M23.8,45.989a3.576,3.576,0,0,0-.462-2.854,3.453,3.453,0,0,0-2.348-1.513c-.181-.03-.183-.044-.219-.179q-.928-3.565-1.9-7.119a.219.219,0,0,1,.075-.282,3.462,3.462,0,0,0,0-4.986.386.386,0,0,1-.07-.087.369.369,0,0,1,.045-.123Q20.9,24.731,22.86,20.6a.793.793,0,0,1,.051-.091.321.321,0,0,1,.093,0,3.514,3.514,0,0,0,2.605-.73,4.123,4.123,0,0,1,.756-.462q4.555-2.071,9.123-4.116a1.546,1.546,0,0,1,.678-.126,3.515,3.515,0,1,0-3.241-4.014c-.018.111-.067.224-.06.229-2.339,1.067-4.688,2.112-7.02,3.192a3.586,3.586,0,0,0-2.818-.936L21.176,6.6l.206-.125A3.508,3.508,0,0,0,19.364,0a3.475,3.475,0,0,0-3.288,2.783,3.461,3.461,0,0,0,.531,2.7,3.513,3.513,0,0,0,2.37,1.488l.092.012c.038.005.09.014.092.028q.465,1.779.94,3.556c.334,1.259.669,2.517.995,3.761a.593.593,0,0,1-.014.078,3.532,3.532,0,0,0-.092,5.16c.012.02.025.07.031.061q-1.984,4.208-4,8.38c-.016.018-.065.049-.061.053A3.5,3.5,0,0,0,13.024,31a.32.32,0,0,1-.089.142c-2.355,1.067-4.717,2.121-7.063,3.156a.478.478,0,0,1-.2-.05c-.144-.065-.282-.144-.42-.223a4.019,4.019,0,0,0-.749-.357A3.373,3.373,0,0,0,1.9,33.9a3.551,3.551,0,0,0-1.743,2.09A3.473,3.473,0,0,0,.437,38.7a3.5,3.5,0,0,0,4.877,1.341,3.289,3.289,0,0,0,1.555-2.082,6.3,6.3,0,0,0,.112-1.418c0-.155,0-.313.005-.474L13.4,33.2a3.514,3.514,0,0,0,3.545,1.844l1.844,6.923a3.5,3.5,0,0,0-1.543,4.624,3.545,3.545,0,0,0,2.186,1.863A3.53,3.53,0,0,0,23.8,45.989m3.439-29.919c1.428-.62,2.849-1.256,4.271-1.893l1.728-.772c.044-.02.088-.038.132-.056l.293.413-6.73,3.04a.9.9,0,0,0-.058-.241c-.1-.245-.113-.284.363-.49M17.107,30.27l.008,0a1.418,1.418,0,0,1,.652,1.894,1.4,1.4,0,0,1-1.884.654l-.005,0a1.415,1.415,0,0,1,1.229-2.549m4.979-12.757a1.416,1.416,0,0,1,1.943-1.749h0a1.415,1.415,0,1,1-1.944,1.748M4.794,37.634a1.4,1.4,0,0,1-1.887.647l-.006,0h0a1.378,1.378,0,0,1-.65-1.882,1.412,1.412,0,0,1,.814-.725,1.389,1.389,0,0,1,1.062.057l.011.005a1.444,1.444,0,0,1,.656,1.9"
        transform="translate(0 0)"
        fill="currentColor"
      />
    </g>
  </svg>
);

export default LacticAcid;
